import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button, Container, Card, Modal, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import CardForm from '../components/CardForm'
import axios from 'axios'
import { getCardsConfiguration } from '../utils/cards'
import { FaCheck } from "react-icons/fa"
import { loadStripe } from '@stripe/stripe-js'


class CardsPage extends React.Component {
  constructor (props) {
    super(props)

    const { mdx } = props.data
    const { frontmatter } = mdx
    const { description, cards } = frontmatter

    this.state = {
        cards: cards,
        description: description,
        cardsConfiguration: {},
        cardModals: {},
        stripe: null,
    }

    this.loadStripeLib = this.loadStripeLib.bind(this)
  }

  async loadStripeLib() {
    try {
      const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
      this.setState({ stripe });
    } catch {
      // do nothing
    }
  }

  componentDidMount() {
    this.loadStripeLib()
    getCardsConfiguration().then(cardsConfiguration => {
        this.setState({
            cardsConfiguration: cardsConfiguration
        })
    })
  }

  handleSubmit(data) {
      axios({method: 'post', url:'/.netlify/functions/card', data: data}).then(response => {
        if (this.state.stripe && response.data.session) {
            this.state.stripe.redirectToCheckout({
                sessionId: response.data.session.id
            }).then(response => {
                console.log("super")
            }).catch(error => {
                console.log("error")
            });
        }
        
      }).catch((err) => {
        console.log(err)
          if (err.response.status === 500) {
              alert("Une erreur technique est survenue, veuillez contacter notre équipe afin qu'on puisse résoudre le problème dans les meilleurs délais")
          }
      })
  }

  renderCards(cards) {

    return <Row className={"row-cols-1 row-cols-md-4 w-xs-50"}>
    {
        cards.map((card, index) => {
          if (! this.state.cardsConfiguration) {
            return null
          }
          const cardConfiguration = card.conf
          if (! cardConfiguration || ! cardConfiguration.price || cardConfiguration.price <= 0) {
            return null
          }

            return (
                <Col key={index} className="my-4">
                    <Modal show={this.state.cardModals[card.name]} onHide={() => {
                          this.setState({
                            cardModals: {...this.state.cardModals, [card.name]: false}
                          })
                        }} centered>
                            <Modal.Header closeButton>
                              <Modal.Title>Commande : {card.name}</Modal.Title>
                            </Modal.Header>

                            <CardForm
                            cardConfiguration={cardConfiguration}
                            handleSubmit={
                                (form) => {
                                  this.handleSubmit({
                                    card_name: card.name,
                                    card_description: card.description,
                                    user_form: form
                                  })
                                }
                              }/>
                          </Modal>
                        <Card className="h-100">
                            <Card.Header className="h3 text-green text-center mb-3">
                              Carte {card.name}
                            </Card.Header>
                            <div style={{ maxHeight: "100%", height: "200px" }}>
                            <Img 
                            style={{ maxHeight: "100%" }}
                            imgStyle={{ objectFit: "contain" }}
                            fluid={card.card_image.childImageSharp.fluid} alt={card.name} className="" />
                            </div>
                            <Card.Body className="">
                              <Card.Title className="mt-3 text-center">
                                  <h2>{cardConfiguration.price}€</h2>
                              </Card.Title>
                              <ReactMarkdown parserOptions={{ commonmark: true }} source={card.description} className="align-text-top"/>
                            </Card.Body>
                            <Card.Footer className="text-muted text-center">
                              <Button onClick={() => {
                                this.setState({
                                  cardModals: {...this.state.cardModals, [card.name]: true}
                                })
                              }} className="my-1">Commander</Button>
                              </Card.Footer>
                        </Card>
                </Col>
            )
        })
    }
  </Row>

  }

  render() {
    const cardsWithConf = this.state.cards.map(card => {
      return {
        ...card,
        conf: this.state.cardsConfiguration[card.name]
      }
    })
      const limitedCards = cardsWithConf.filter(card => card.conf)
      return (
        <Layout>
          <SEO title="Cartes d'accès" />
          
          <Container className="py-4 text-justify">

            <div id ="cards">
                <h2 className="my-4 text-center">Cartes d'accès</h2>
                <ReactMarkdown parserOptions={{ commonmark: true }} source={this.state.description} className="h5"/>

                <hr />
                {
                    this.renderCards(limitedCards)
                }

            </div>
        </Container>
      </Layout>
    )
  }
}

export default CardsPage

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                description
                cards {
                  name
                  description
                  card_image {
                    childImageSharp {
                        fluid(quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
            }
        }
    }`
